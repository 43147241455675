import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { formatDate } from "../utils/data"

const Exhibits = ({ data }) => {
  return (
    <Layout>
      <SEO title="Exhibits" />
      <div className="max-w-5xl mx-auto w-full">
        <div className="my-8 text-center">
          <h1 className="my-8">Exhibits</h1>
        </div>
        <div className="flex flex-wrap">
          {data.allPrismicExhibit.edges.map(({ node }, i) => {
            const {
              name,
              subtitle,
              start_date,
              end_date,
              image,
              short_description,
            } = node.data
            return (
              <Link
                key={i}
                to={`/${node.uid}/`}
                className={classnames(
                  "no-underline flex w-full flex-col md:flex-row justify-center items-center py-10 px-4 border-2 border-transparent hover:border-green transition duration-150",
                  {
                    "md:flex-row-reverse": i === 1,
                  }
                )}
              >
                <Img
                  fluid={{ ...image.fluid }}
                  className="w-full flex-1"
                  imgStyle={{ objectFit: "contain" }}
                />
                <div className="flex-1 my-8">
                  <div
                    className={classnames("max-w-md px-6", {
                      "ml-auto": i === 1,
                    })}
                  >
                    <div className="pb-4">
                      <h2>{name.text}</h2>
                      {subtitle && <h4>{subtitle}</h4>}
                    </div>
                    {short_description && (
                      <p className="pb-4">{short_description}</p>
                    )}
                    <p>
                      {formatDate(start_date)} - {formatDate(end_date)}
                    </p>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Exhibits

export const query = graphql`
  {
    allPrismicExhibit(sort: { fields: data___start_date, order: DESC }) {
      edges {
        node {
          uid
          data {
            name {
              text
            }
            subtitle
            start_date
            end_date
            image {
              fluid(maxWidth: 500) {
                ...GatsbyPrismicImageFluid
              }
            }
            short_description
          }
        }
      }
    }
  }
`
